<template>
  <div :class="className" :style="tabContainerStyle">
    <div v-for="n in total" :key="n" :class="`${className}__tab`" />
  </div>
</template>

<script>
export default {
  name: 'SuspenseText',

  props: {
    total: {
      type: Number,
      default: 4,
    },
    gap: {
      type: String,
      default: '1rem',
      validator: (x) => x.endsWith('rem') || x.endsWith('px'),
    },
    height: {
      type: String,
      default: '1rem',
      validator: (x) => x.endsWith('rem') || x.endsWith('px'),
    },
  },

  data() {
    return {
      className: 'the-suspense-tab',
    }
  },

  computed: {
    tabContainerStyle() {
      return `grid-template-columns: repeat(${this.total}, minmax(0, 1fr)); gap: ${this.gap};`
    },
    tabItemStyle() {
      return `height: ${this.height};`
    },
  },
}
</script>
